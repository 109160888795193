<template>
  <v-container>
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          align="center"
          cols="12"
      >
        <h1
            class="contactUsHeader"
        >
          Contact Us
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider/>
      </v-col>
    </v-row>
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          align="center"
          cols="12"
      >
        <v-card
            color="transparent"
            flat
        >
          <v-card-title
              class="justify-center text-center text-no-wrap"
          >
            <h3 class="reachOutHeader">
              Reach Out to Us
            </h3>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-row
                align="center"
                justify="center"
            >
              <v-col
                  align-self="center"
                  cols="12"
                  lg="6"
                  md="12"
                  sm="12"
                  xl="6"
                  xs="12"
              >
                <span>
              <v-icon color="#80b192" style="padding-right: 5px" x-large>
                mdi-email
              </v-icon>
              <a href="mailto:lets-talk@speechpathsslp.com" style="text-decoration: none; color: #535353">lets-talk@speechpathsslp.com</a>
            </span>
              </v-col>
              <v-col
                  align-self="center"
                  cols="12"
                  lg="6"
                  md="12"
                  sm="12"
                  xl="6"
                  xs="12"
              >
                <span>
              <v-icon color="#80b192" style="padding-right: 5px" x-large>
                mdi-phone
              </v-icon>
              <a href="tel:+16086692145" style="text-decoration: none; color: #535353">(608) 669-2145</a>
            </span>
              </v-col>
            </v-row>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <prospect-client-form/>
        <v-divider/>
      </v-col>
    </v-row>
    <tagline/>
  </v-container>
</template>

<script>
import Tagline from "../components/Tagline";
import ProspectClientForm from "../components/ProspectClientForm";

export default {
  name: "ContactUs",
  components: {ProspectClientForm, Tagline},
  data() {
    return {}
  }
}
</script>

<style scoped>
.contactUsHeader {
  color: #80b192;
}

.reachOutHeader {
  color: #6a8d92;
}

</style>