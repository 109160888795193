<template>
  <v-container>
    <v-card
        color="transparent"
        flat
    >
      <v-card-title
          class="justify-center title text-no-wrap"
      >
        <h1>
          Speech Paths
        </h1>
      </v-card-title>
      <v-card-subtitle
          align="center"
          class="justify-center subtitle text-no-wrap pa-4"
      >
        <h1>
          Speech Therapy
        </h1>
      </v-card-subtitle>
      <v-card-title
          align="center"
          class="justify-center text text-break"
          style="font-style: italic"
      >
        <h4>
          Serving Madison & Its Surrounding Communities
        </h4>
      </v-card-title>
    </v-card>
    <v-divider></v-divider>
    <v-card
        color="transparent"
        flat
    >
      <v-card-text
          class="text-break text-center text text-lg-body-1"
      >
        <p>
          The path to communication is not one size fits all but rather each child has their own unique path to
          communication.
        </p>
        <p>
          Speech Paths was founded to lead each child down their own path to communication that connects them with the
          world around them.
        </p>
        <p>
          Speech Paths provides speech therapy in the child’s home, daycare, or virtual environments.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row
              align="center"
              justify="center"
          >
            <v-col
                align="center"
                cols="12"
                lg="4"
                md="12"
                sm="12"
                xl="4"
                xs="12"
            >
              <v-btn
                  to="/about-us"
                  color="#6a8d92"
                  dark
              >
                Learn About Us
              </v-btn>
            </v-col>
            <v-col
                align="center"
                cols="12"
                lg="4"
                md="12"
                sm="12"
                xl="4"
                xs="12"
            >
              <v-btn
                  to="/services"
                  color="#6a8d92"
                  dark
              >
                View Services
              </v-btn>
            </v-col>
            <v-col
                align="center"
                cols="12"
                lg="4"
                md="12"
                sm="12"
                xl="4"
                xs="12"
            >
              <v-btn
                  to="/contact"
                  color="#6a8d92"
                  dark
              >
                Contact Us
              </v-btn>
            </v-col>
          </v-row>

        </v-container>
      </v-card-actions>

      <v-spacer></v-spacer>
      <v-card-text>
        <tagline/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Tagline from "../components/Tagline";
export default {
  name: "Home",
  components: {Tagline}
}
</script>

<style scoped>
.title {
  color: #80b192;
}

.subtitle {
  color: #6a8d92 !important;
}

.text {
  color: #535353;
}
</style>