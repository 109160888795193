<template>
  <v-card
      :flat="flat"
      class="definitionCard px-0"
      dark
      @click="showDefinition = !showDefinition"
  >
    <v-card-title
        class="justify-center text-no-wrap"
        primary-title
    >
      {{ term }}
    </v-card-title>

    <v-expand-transition>
      <div v-show="showDefinition">
        <v-divider></v-divider>

        <v-card-text
            v-if="definition"
            class="text-break text-center text-lg-center"
        >
          {{ definition }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "DefinitionCard",
  data() {
    return {
      showDefinition: false,
    }
  },
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    term: {
      type: String,
      required: true
    },
    definition: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

.definitionCard {
  background-color: rgba(83, 83, 83, 1.1);
}

</style>