<template>
  <v-container>
    <v-card
        color="transparent"
        flat
    >
      <v-card-title
          class="justify-center text-center text-no-wrap"
      >
        <h3 class="formHeader">
          Let's Get In Touch
        </h3>
      </v-card-title>

      <v-card-text>
        <v-form
            v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
                  md="12"
                  sm="12"
                  xl="6"
                  xs="12"
              >
                <v-text-field
                    v-model="email_address"
                    color="#80b192"
                    label="Email Address"
                    required
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="12"
                  sm="12"
                  xl="6"
                  xs="12"
              >
                <v-text-field
                    v-model="phone_number"
                    color="#80b192"
                    label="Phone Number"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xl="6"
                  xs="6"
              >
                <v-text-field
                    v-model="first_name"
                    color="#80b192"
                    label="First Name"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xl="6"
                  xs="6"
              >
                <v-text-field
                    v-model="last_name"
                    color="#80b192"
                    label="Last Name"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xl="6"
                  xs="6"
              >
                <v-text-field
                    v-model="client_first_name"
                    color="#80b192"
                    label="Child's First Name"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xl="6"
                  xs="6"
              >
                <v-text-field
                    v-model="client_last_name"
                    color="#80b192"
                    label="Child's Last Name"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xl="6"
                  xs="6"
              >
                <v-checkbox
                    v-model="has_diagnosis"
                    color="#80b192"
                    label="Has your child received a diagnosis?"
                >
                </v-checkbox>
              </v-col>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  xl="6"
                  xs="6"
              >
                <v-text-field
                    v-if="has_diagnosis"
                    v-model="diagnosis"
                    color="#80b192"
                    label="Diagnosis"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xl="12"
                  xs="12"
              >
                <v-textarea
                    v-model="concerns"
                    color="#80b192"
                    label="What are your concerns?"
                    placeholder="Concerns"
                >
                </v-textarea>
              </v-col>
              <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xl="12"
                  xs="12"
              >
                <v-textarea
                    v-model="comment"
                    color="#80b192"
                    label="Anything else we should know?"
                    placeholder="Comments"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row
            >
              <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xl="12"
                  xs="12"
              >
                <v-btn
                    color="#6a8d92"
                    dark
                    @click="submitProspectClient"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
            <v-row
                align="center"
                justify="center"
            >
              <v-col
                  align="center"
                  align-self="center"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xl="12"
                  xs="12"
              >
                <span
                    class="font-italic font-weight-light text-break"
                >
                  The information shared on this contact sheet is handled in accordance with HIPAA regulations and maintains confidentiality.
                </span>

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
    <v-dialog
        v-model="successDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title
            class="justify-center text-center text-no-wrap"
        >
          <h3 class="formHeader">
            Success
          </h3>
        </v-card-title>

        <v-card-text
            class="dialogMessage text-center justify-center text-break"
        >
          We'll be in touch soon!
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#6a8d92"
              text
              @click="successDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="failDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title
            class="justify-center text-center text-no-wrap"
        >
          <h3 class="formHeader">
            Uh-Oh
          </h3>
        </v-card-title>

        <v-card-text
            class="dialogMessage text-center justify-center text-break"
        >
          Something went wrong. Please try again.
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#6a8d92"
              text
              @click="failDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import http from "../http-common";

export default {
  name: "ProspectClientForm",
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: undefined,
      valid: false,
      email_address: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      client_first_name: '',
      client_last_name: '',
      has_diagnosis: false,
      diagnosis: '',
      concerns: '',
      comment: '',
      successDialog: false,
      failDialog: false,
    }
  },
  methods: {
    resetForm() {
      this.email_address = '';
      this.first_name = '';
      this.last_name = '';
      this.phone_number = '';
      this.client_first_name = '';
      this.client_last_name = '';
      this.has_diagnosis = false;
      this.diagnosis = '';
      this.concerns = '';
      this.comment = '';
    },
    async submitProspectClient() {
      const prospectClientForm = {
        email_address: this.email_address,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone_number,
        client_first_name: this.client_first_name,
        client_last_name: this.client_last_name,
        diagnosis: this.diagnosis,
        concerns: this.concerns,
        comment: this.comment
      };
      try {
        const response = await http.post(
            "/prospect-clients/create",
            prospectClientForm,
        );
        this.resetForm();
        this.successDialog = true;
        console.log("Response: ", JSON.stringify(response, null, 2));
      } catch (err) {
        console.log("Error: ", JSON.stringify(err, null, 2));
        this.failDialog = true;
      }
    }
  }
}
</script>

<style scoped>
.formHeader {
  color: #6a8d92;
}

.dialogMessage {
  font-size: large;
  color: #535353;
}

</style>