import axios from "axios";
import {API_URL} from "./common/config";

const API_KEY = process.env.VUE_APP_API_KEY;

export default axios.create(
    {
        baseURL: API_URL,
        headers: {
            "Content-type": "application/json",
            "Authorization": API_KEY,
        }
    }
);