<!--
  Colors
  Grey: #535353
  Blue: #6a8d92
  Green: #80b192
-->

<template>
  <v-app id="speech-paths">'
    <v-app-bar
        :height="appBarHeight"
        app
        flat
        max-height="200"
        min-height="50"
    >
      <v-toolbar-title>
        <v-img
            :height="appBarHeight"
            :width="appBarHeight"
            contain
            max-height="200"
            max-width="200"
            src="./assets/SpeechPathsLogo.png"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="isMobile">
      <v-app-bar-nav-icon
          v-if="isMobile"
          class="text"
          @click.stop="drawer = !drawer"
      />
      </v-toolbar-title>
      <div v-if="!isMobile">
        <v-btn
            v-for="(link, index) in links" :key="index"
            :to="link.to"
            active-class="active"
            color="#535353"
            plain
        >
          {{ link.name }}
        </v-btn>
      </div>

    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        app
        temporary
        right
    >
      <v-list
          dense
          nav
      >
        <v-list-item-group
            v-model="group"
            active-class="active">
          <v-list-item
              v-for="(link, index) in links"
              :key="index"
              :to="link.to"
          >
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="app">
      <v-divider/>
      <router-view/>
    </v-main>

    <v-footer
        app
        padless
        color="#535353"
        absolute
    >
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            class="py-4 text-center white--text"
            cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Speech Paths</strong>
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.title = "Speech Paths";
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    height() {
      return this.$vuetify.breakpoint.height
    },
    width() {
      return this.$vuetify.breakpoint.width
    },
    appBarHeight() {
      return Math.round(this.$vuetify.breakpoint.height / 8) + 'px';
    },
    appBarWidth() {
      return '100%';
    }
  },
  data: () => ({
    drawer: false,
    group: null,
    links: [
      {
        name: 'Home',
        to: '/'
      },
      {
        name: 'About Us',
        to: '/about-us'
      },
      {
        name: 'Services',
        to: '/services'
      },
      {
        name: 'Contact',
        to: '/contact'
      }
    ]
  }),
  watch: {
    group() {
      this.drawer = false
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Bahnscrift";
  src: local("Bahnscrift"),
  url(./fonts/BAHNSCHRIFT.TTF) format("truetype");
}

.app {
  font-family: "Bahnscrift",serif;
  background-color: rgba(243, 243, 243, 0.87);
}

.full-height {
  height: 100%;
}

.toolbar-title {
  color: #535353;
}

.subtitle {
  color: #6a8d92;
}

.text {
  color: #535353;
}

.active {
  color: #80b192 !important;
}
</style>
