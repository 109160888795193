<template>
  <v-container>
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          align="center"
          cols="12"
      >
        <h1
            class="aboutUsHeader"
        >
          About Us
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider/>
      </v-col>
    </v-row>
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          lg="10"
          md="10"
          sm="12"
          xl="10"
          xs="12"
      >
        <v-card
            class="employeeCard"
            elevation="9"
        >
          <v-card-title>
            <h3 class="employeeName">
              Brenda Vicen
            </h3>
          </v-card-title>
          <v-card-subtitle>
            <h4>
              Owner, MS / CCC-SLP
            </h4>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text
              class="employeeDescription justify-center text-center text-break"
          >
            <p>
              Brenda Vicen holds the American Speech-Language-Hearing Association (ASHA) Certificate of Clinical Competence in Speech-Language Pathology (CCC-SLP).
            </p>
            <p>
              She is licensed by both the Department of Safety and Professional Services and Department of Public Instruction in the state of Wisconsin.
            </p>
            <p>
              She has 29 years of experience working with children and their families from birth to 21.
            </p>
            <p>
              Brenda is committed to helping individuals find their own path to meaningful communication.
            </p>
          </v-card-text>
          <v-card-actions>
            <span>
              <v-icon color="#80b192" style="padding-right: 5px" large>
                mdi-email
              </v-icon>
              <a href="mailto:brenda@speechpathsslp.com" style="text-decoration: none; color: #535353">brenda@speechpathsslp.com</a>
            </span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <tagline/>
  </v-container>
</template>

<script>
import Tagline from "../components/Tagline";

export default {
  name: "AboutUs",
  components: {Tagline},
  data() {
    return {}
  }
}
</script>

<style scoped>

.aboutUsHeader {
  color: #80b192;
}

.employeeCard {
  background-color: rgba(106, 141, 146, 0.25);
}

.employeeName {
  color: #535353;
}

.employeeDescription {
  font-size: large;
  color: #535353;
}


</style>