<template>
  <v-container>
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          align="center"
          cols="12"
      >
        <h1
            class="servicesHeader"
        >
          Services
        </h1>
      </v-col>
    </v-row>
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          align="center"
          cols="12"
      >
        <h4
            class="servicesSubHeader1 text-center text-pre-wrap"
        >
          Speech Paths provides speech therapy in the child’s home, daycare, or virtual environments.
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider/>
      </v-col>
    </v-row>

    <v-row
        v-for="(service, index) in services"
        :key="index"
        align="center"
        justify="center"
    >
      <v-col
          align="center"
          cols="12"
          lg="12"
          md="12"
          sm="12"
          xl="10"
          xs="12"
      >
        <service-card
            :service-components="service.components"
            :service-definitions="service.definitions"
            :service-descriptions="service.descriptions"
            :service-name="service.name"
        />
      </v-col>
    </v-row>

    <tagline/>
  </v-container>
</template>

<script>
import ServiceCard from "../components/ServiceCard";
import Tagline from "../components/Tagline";

export default {
  name: "Services",
  components: {Tagline, ServiceCard},
  data() {
    return {
      services: [
        {
          name: 'Evaluation',
          descriptions: [
            'Speech and language evaluations are completed to assess a child’s strengths and challenges in communication.',
            'In addition, it provides a foundation and baseline for treatment goals.',
            'The results of the evaluation will be written into a report and shared within a meeting with the parents.  This provides the opportunity for the speech-language pathologist to explain how the results impact the child, for the family to ask questions, and for the team to collaborate to determine the best treatment plan for the child.'
          ],
          components: [
            {
              subheader: 'The evaluation may include the following components:',
              subComponents: [
                'Parent interview or intake form',
                'Direct observation of the child in play and interaction',
                'Formal, standardized tests or review of recent speech and language evaluation completed elsewhere'
              ]
            },
            {
              subheader: 'The areas of development to be evaluated are based on the parent’s concerns.  Possible areas include:',
              subComponents: [
                'Receptive Language; including understanding of concepts, directions and narratives',
                'Expressive Vocabulary',
                'Use of grammar and syntax',
                'Narrative development',
                'Articulation of speech sounds',
                'Feeding & Eating skills',
                'Executive Functioning'
              ]
            }
          ]
        },
        {
          name: 'Treatment',
          descriptions: [
            'Speech Paths provides speech therapy in the child’s home, daycare, or virtual environments.',
            'Each session includes direct treatment with the child and a 10-15 minute parent consultation following each session.'
          ],
          definitions: [
            {
              term: 'Articulation',
              definition: 'working on the sounds and clarity of spoken words'
            },
            {
              term: 'Receptive Language',
              definition: 'how a child understand spoken and nonverbal language'
            },
            {
              term: 'Expressive Language',
              definition: 'the ability to use words, sentences, gestures, and writing to communicate with others'
            },
            {
              term: 'AAC',
              definition: 'using pictures or technology to use or clarify words and sentences'
            },
            {
              term: 'Feeding',
              definition: 'working on making eating safe and enjoyable'
            },
            {
              term: 'Fluency',
              definition: 'the ability to speak easily and smoothly'
            },
            {
              term: 'Neurodiversity',
              definition: 'understand and respect neurological differences'
            },
            {
              term: 'Self-Advocacy',
              definition: 'the ability to communicate one’s needs'
            },
            {
              term: 'Executive Functioning',
              definition: 'refers to the processes that are responsible for the planning, monitoring, and successful carrying out of tasks, plans, or goals'
            },
            {
              term: 'Social Connection',
              definition: 'building a sense of belonging and community through connection to others'
            },
            {
              term: 'Hidden Curriculum',
              definition: 'social expectations or rules that are not taught but assumed learned'
            },
          ]
        },
        {
          name: 'Fees & Payments',
          descriptions: [
            'Speech Paths does not directly bill health insurances. This provides us the ability to address functional and meaningful therapeutic outcomes that improve the individual’s quality of life without being compromised by the restrictions placed on treatment by insurance companies.',
            'Fees may be reimbursable by individual health insurance plans, flexible spending accounts (FSAs), or Health Savings Accounts (HSAs). The family is responsible for exploring and pursuing these options. An itemized receipt including diagnostic and treatment codes will be provided following each payment.',
            'Electronic payments are required prior to each session. An itemized receipt including diagnostic and treatment codes will be provided following each payment.',
            'Rates are based on session length.  All rates include: direct treatment and 10-15 minute parent consultation after each session. Length of session is based on goals and intervention plan and determined collaboratively with parents.'
          ],
        },

      ]
    }
  }
}
</script>

<style scoped>
.servicesHeader {
  color: #80b192;
}

.servicesSubHeader1 {
  color: #535353;
}

</style>