<template>
  <v-card color="transparent" flat class="pa-12">
    <v-card-text align="center" style="font-size: x-large; font-style: italic; color: #80b192">
      <v-container>
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              align="center"
              cols="12"
              lg="3"
              md="10"
              sm="10"
              xl="3"
              xs="10"
          >
            <span class="tagline text-break">
              Communication
            </span>
          </v-col>
          <v-col
              align="center"
              cols="12"
              lg="1"
              md="12"
              sm="12"
              xl="1"
              xs="12"
          >
            <v-icon :color="starColor" class="tagline text-break" size="large">
              mdi-star-four-points
            </v-icon>
          </v-col>
          <v-col
              align="center"
              cols="12"
              lg="3"
              md="10"
              sm="10"
              xl="3"
              xs="10"
          >
            <span class="tagline text-break">
              Connection
            </span>
          </v-col>
          <v-col
              align="center"
              cols="12"
              lg="1"
              md="12"
              sm="12"
              xl="1"
              xs="12"
          >
            <v-icon :color="starColor" class="tagline text-break" size="large">
              mdi-star-four-points
            </v-icon>
          </v-col>
          <v-col
              align="center"
              cols="12"
              lg="3"
              md="10"
              sm="10"
              xl="3"
              xs="10"
          >
            <span class="tagline text-break">
              Community
            </span>
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Tagline",
  props: {
    color: {
      type: String,
      default: "#80b192"
    },
    starColor: {
      type: String,
      default: "#535353"
    }
  }
}
</script>

<style scoped>

.tagline {
  padding-right: 10px;
  padding-left: 10px;
}

</style>