<template>
  <v-card
      :flat="flat"
      class="serviceCard"
      elevation="9"
  >
    <v-card-title
        class="serviceName justify-center text-no-wrap"
    >
      <h3>
        {{ serviceName }}
      </h3>
    </v-card-title>
    <v-divider/>

    <v-card-text
        v-if="serviceDescriptions"
        class="serviceDescription justify-center text-center text-break"
    >
      <p
          v-for="(description, index) in serviceDescriptions"
          :key="index"
      >
        {{ description }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          v-if="serviceDefinitions || serviceComponents"
          plain
          @click="showMore = !showMore"
      >
        <span v-if="!showMore">
          Show More
        </span>
        <span v-if="showMore">
          Show Less
        </span>
        <v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="showMore">
        <v-divider></v-divider>

        <v-card-text v-if="serviceDefinitions">
          <v-container>
            <v-row
                align="center"
                justify="center"
            >
              <v-col
                  v-for="(definition, index) in serviceDefinitions"
                  :key="index"
                  align-self="stretch"
                  cols="12"
                  lg="4"
                  md="12"
                  sm="12"
                  xl="4"
                  xs="12"
              >
                <definition-card
                    :definition="definition.definition"
                    :term="definition.term"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="serviceComponents">
          <v-container>
            <v-row
                v-for="(component, index) in serviceComponents"
                :key="index"
                class="pa-4"
            >
              <v-row
                  align="start"
                  justify="start"
              >
                <v-col
                    align="start"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xl="12"
                    xs="12"
                >
                  <h4
                      class="serviceDescription pa-4"
                  >
                    {{ component.subheader }}
                  </h4>
                </v-col>
              </v-row>
              <v-row
                  align="start"
                  justify="start"
              >
                <v-col
                    v-for="(subcomponent, index) in component.subComponents"
                    :key="index"
                    align="start"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xl="12"
                    xs="12"
                >
                  <span class="serviceDescription justify-center text-center text-break">
                    <v-icon>
                      mdi-square-small
                    </v-icon>
                    {{ subcomponent }}
                  </span>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import DefinitionCard from "./DefinitionCard";

export default {
  name: "ServiceCard",
  components: {DefinitionCard},
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'transparent'
    },
    serviceName: {
      type: String,
      required: true
    },
    serviceDescriptions: {
      type: Array,
      required: false
    },
    serviceDefinitions: {
      type: Array,
      required: false
    },
    serviceComponents: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      showMore: false,
      serviceNameLower: this.serviceName.toLowerCase()
    }
  }
}
</script>

<style scoped>

.serviceCard {
  background-color: rgba(106, 141, 146, 0.25);
}

.serviceName {
  color: #535353;
}

.serviceDescription {
  font-size: large;
  color: #535353;
}

</style>